/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Bithynia Dönemi'nde (MÖ 4'ncü yüzyıl) inşa edilmeye başlanan surlar, Roma ve Bizans dönemlerindeki yeni eklentilerle günümüzdeki şeklini almıştır. İznik’in çevresini beş kenarlı çokgen şekilde kuşatan surlar yaklaşık 4 bin 970 metre uzunluğundadır. Yüksekliği 10-13 metre arasında değişen surlarda, yuvarlak ve kare şeklinde 114 burç vardır. İznik’in iki ana caddesinin kesiştiği noktadan bakıldığında, dört ana kapı görünür."), "\n", React.createElement(_components.p, null, "Lefke Kapı, kentin doğu ucunda yer alan kapı, İstanbul Kapısı ile büyük benzerlik gösterir. Aynı tarihlerde yapılma olasılığı güçlü görülmekte, ancak her iki kapı için de kesin bir tarih belirlenememektedir. Büyük olasılıkla önceden avlu içinde iken Bizans Dönemi'nde eklenen yapılarla avlu kapatılmış ve bugün gördüğümüz koridor şeklinde yol olmuştur. Kente dışarıdan girişte iki silindirik kulenin arasında kalın mermer sütunların oluşturduğu üst silmesi ağaçtan olan dikdörtgen kapı yer alır. Kapı üzerindeki kemerin içi örülmüştür. Ayrıca kapının sol tarafında devşirme, yüksek kabartma görünümü veren bir friz vardır. Üzerinde bulunan insan figürleri çok sıkışık olarak gösterilmiştir. Orta kapı kemeri üzerinde içte ve dışta birer yazıt vardır. İç tarafındakinde “Bu surları imparatorluk hanedanı ve İmparator Hadrianus adına ünlü İznik metropolü Cassius’un gözetimi ve denetiminde yaptırılmıştır.\" dışarıdakinde ise “... senesinde imparatorluğa gönderilen paralar ile kent halkı bu yapıyı yaptırmıştır” bulunmaktadır. Kesme taşlardan üzeri tuğla kemerli bir geçit yer almakta olup, sağ tarafında moloz taş ve tuğla sıralarından bir kule vardır."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
